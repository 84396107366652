import { useEffect, useState } from "react";
import PropTypes from "prop-types";
/* Components */
import { Card } from "semantic-ui-react";
import { Row, Col, Button } from "react-bootstrap";
import BlurredNumber from "../CommonForBoth/Blurred/BlurredNumber";
/* Selectors */
import { selectEthCallMultiple, selectLastTransact } from "@ensuro/ethereum-store/src/store/ethereum/selectors";
import { selectAPICallMultiple } from "@ensuro/api-calls-store/src/store/api/selectors";
import { selectUserAddress } from "../../store/user/selectors";
import { selectCurrent } from "../../store/eTokens/selectors";
/* Helpers */
import { useRollbarPerson } from "@rollbar/react";
/* Modals */
import DepositModal from "../Modal/DepositModal";
import WithdrawModal from "../Modal/WithdrawModal";
import TxModal from "../Modal/TxModal";
import MintModal from "../Modal/MintModal";
import ConnectButton from "../CommonForBoth/ConnectButton";
import RestrictedModal from "../Modal/RestrictedModal";
import QuadrataModal from "../Modal/QuadrataModal";
/* i18n and config */
import { withTranslation } from "react-i18next";
import { connect, useDispatch } from "react-redux";
import { currency, environment } from "../../config";
import "./single_eToken.scss";

const makeEthCalls = function (userAddress, etkAddress) {
  return [
    { address: currency.address, abi: "ERC20", method: "balanceOf", args: [userAddress] },
    { address: etkAddress, abi: "EToken", method: "balanceOf", args: [userAddress] },
  ];
};

const makeWhitelistCall = (userAddress, etkAddress, etkWhitelist) => {
  return [
    {
      address: etkWhitelist.address,
      abi: "ILPWhitelist",
      method: "acceptsDeposit(address,address,uint256)",
      args: [etkAddress, userAddress, 1],
    },
    {
      address: etkWhitelist.address,
      abi: "ILPWhitelist",
      method: "acceptsWithdrawal(address,address,uint256)",
      args: [etkAddress, userAddress, 1],
    },
  ];
};

const makeAPICalls = (userAddress) => {
  return [{ apiName: "lpsDeposits", args: [userAddress] }];
};

const UserInformation = ({
  t,
  user,
  userAddress,
  etkAddress,
  etkSymbol,
  usdcBalance,
  etkBalance,
  lastTx,
  acceptsDeposit,
  acceptsWithdrawal,
  etkWhitelist,
  lpsDeposits,
}) => {
  const [depositModal, showDepositModal] = useState(false);
  const [withdrawModal, showWithdrawModal] = useState(false);
  const [txModal, showTxModal] = useState(false);
  const [mintModal, showMintModal] = useState(false);
  const [wlModalVisible, showWLModal] = useState(false);
  let dispatch = useDispatch();

  useRollbarPerson(user);

  // Ethereum and API useEffects
  useEffect(() => {
    if (userAddress) {
      dispatch({
        type: "ETH_ADD_SUBSCRIPTION",
        key: "userInfo",
        componentEthCalls: makeEthCalls(userAddress, etkAddress),
        clockCount: 10,
      });
      if (etkWhitelist !== null) {
        dispatch({
          type: "ETH_ADD_SUBSCRIPTION",
          key: "userWhitelist",
          componentEthCalls: makeWhitelistCall(userAddress, etkAddress, etkWhitelist),
        });
      }
      dispatch({
        type: "API_ADD_SUBSCRIPTION",
        key: "userInfo",
        componentApiCalls: makeAPICalls(userAddress),
        clockCount: 10,
      });
    }
    return () => {
      dispatch({ type: "API_REMOVE_SUBSCRIPTION", key: "userInfo" });
      dispatch({ type: "ETH_REMOVE_SUBSCRIPTION", key: "userInfo" });
      dispatch({ type: "ETH_REMOVE_SUBSCRIPTION", key: "userWhitelist" });
    };
  }, [dispatch, etkAddress, etkWhitelist, userAddress]);

  useEffect(() => {
    if (lastTx && lastTx.state === "QUEUED" && (depositModal || withdrawModal || mintModal) && !txModal) {
      showDepositModal(false);
      showWithdrawModal(false);
      showMintModal(false);
      showTxModal(true);
    }
  }, [lastTx, depositModal, withdrawModal, txModal, mintModal]);

  const canDeposit = () => {
    return acceptsDeposit && acceptsDeposit.value; // acceptsDeposit.value === true ==> Whitelisted
  };

  const canWithdraw = () => {
    return acceptsWithdrawal && acceptsWithdrawal.value; // acceptsWithdrawal.value === true ==> Whitelisted
  };

  let net = { value: 0, state: "LOADED" };
  let earned = {};
  if (lpsDeposits && lpsDeposits.value) {
    let etk = lpsDeposits.value.find((d) => d.e_token.address === etkAddress);
    net = { value: etk ? etk.net_deposits : 0, state: "LOADED" };
  }
  earned = etkBalance.state === "LOADED" ? { value: etkBalance.value - net.value, state: "LOADED" } : {};

  const whitelistType =
    etkWhitelist === null ? "none" : etkWhitelist?.implementation === "QuadrataWhitelist" ? "quadrata" : "restricted";

  return (
    <>
      <Card fluid className="your-info-padding right-padding">
        <h4>{t("Your Info")}</h4>
        <br />

        {!userAddress ? (
          <>
            <p>{t("PleaseConnectYourWalletTxt")}</p>
            <br />
            <div className="your-info-buttons">
              <ConnectButton user={user} />
            </div>
          </>
        ) : (
          <>
            <Row>
              <Col md={8}>
                <p>
                  {t("Available to Supply")}
                  {environment.testnet ? (
                    <strong className="btn-text" onClick={() => showMintModal(true)}>
                      {t("[MINT!]")}
                    </strong>
                  ) : (
                    <></>
                  )}
                </p>
              </Col>
              <Col md={4}>
                <p>
                  <BlurredNumber
                    number={usdcBalance}
                    suffix={` ${currency.name}`}
                    options={{ maximumFractionDigits: 2, notation: "standard" }}
                  />
                </p>
              </Col>
            </Row>

            <Row>
              <Col md={8}>
                <p>{t("Your Balance")}</p>
              </Col>
              <Col md={4}>
                <p>
                  <BlurredNumber
                    number={etkBalance}
                    suffix={` ${etkSymbol}`}
                    options={{ maximumFractionDigits: 2, notation: "standard" }}
                  />
                </p>
              </Col>
            </Row>

            <Row>
              <Col md={8}>
                <p>{t("Net Deposits")}</p>
              </Col>
              <Col md={4}>
                <p>
                  <BlurredNumber
                    number={net}
                    suffix={` ${etkSymbol}`}
                    options={{ maximumFractionDigits: 2, notation: "standard" }}
                  />
                </p>
              </Col>
            </Row>

            <Row>
              <Col md={8}>
                <p>{t("Earned")}</p>
              </Col>
              <Col md={4}>
                <p>
                  <BlurredNumber
                    number={earned}
                    suffix={` ${etkSymbol}`}
                    options={{ maximumFractionDigits: 2, notation: "standard" }}
                  />
                </p>
              </Col>
            </Row>

            <br />

            <div className="your-info-buttons">
              <Button
                variant="secondary"
                onClick={() => (canDeposit() ? showDepositModal(true) : showWLModal(true))}
                disabled={acceptsDeposit.value === undefined || usdcBalance.value === undefined}
              >
                {t("Deposit")}
              </Button>
              <Button
                variant="secondary"
                onClick={() => (canWithdraw() ? showWithdrawModal(true) : showWLModal(true))}
                disabled={etkBalance.value === undefined || etkBalance.value.eq(0)}
              >
                {t("Withdraw")}
              </Button>
            </div>
          </>
        )}
      </Card>

      {whitelistType === "quadrata" && (
        <QuadrataModal
          show={wlModalVisible}
          onHide={() => showWLModal(false)}
          onContinue={() => showDepositModal(true)}
        />
      )}
      {whitelistType === "restricted" && <RestrictedModal show={wlModalVisible} onHide={() => showWLModal(false)} />}
      {mintModal && <MintModal show={mintModal} onHide={() => showMintModal(false)} />}
      <DepositModal show={depositModal} onHide={() => showDepositModal(false)} balance={usdcBalance} />
      {withdrawModal && (
        <WithdrawModal show={withdrawModal} onHide={() => showWithdrawModal(false)} balance={etkBalance} />
      )}
      {lastTx && (lastTx.state === "QUEUED" || lastTx.state === "MINED" || lastTx.state === "EXPIRED") && (
        <TxModal show={txModal} onHide={() => showTxModal(false)} tx={lastTx || {}} />
      )}
    </>
  );
};

UserInformation.propTypes = {
  t: PropTypes.any,
};

const mapStateToProps = (state) => {
  const user = state.UserReducer;
  const userAddress = selectUserAddress(state.UserReducer);
  const etk = selectCurrent(state.ETokensReducer);
  const etkAddress = etk?.address;
  const etkSymbol = etk?.symbol;
  const etkWhitelist = etk && etk.whitelist;
  const lastTx = selectLastTransact(state.EthereumReducer);

  const [lpsDeposits] = selectAPICallMultiple(state.APIReducer, makeAPICalls(userAddress));
  const [usdcBalance, etkBalance] = !userAddress
    ? [{}, {}]
    : selectEthCallMultiple(state.EthereumReducer, makeEthCalls(userAddress, etkAddress));

  const [acceptsDeposit, acceptsWithdrawal] =
    !userAddress || etkWhitelist === undefined
      ? [{}, {}]
      : etkWhitelist === null
      ? [
          { state: "LOADED", value: true },
          { state: "LOADED", value: true },
        ]
      : selectEthCallMultiple(state.EthereumReducer, makeWhitelistCall(userAddress, etkAddress, etkWhitelist));

  return {
    user,
    userAddress,
    etkAddress,
    etkSymbol,
    usdcBalance,
    etkBalance,
    lastTx,
    acceptsDeposit,
    acceptsWithdrawal,
    etkWhitelist,
    lpsDeposits,
  };
};

export default connect(mapStateToProps)(withTranslation()(UserInformation));
