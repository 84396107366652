import React from "react";
import PropTypes from "prop-types";
/* Components */
import WrongNetworkAlert from "./WrongNetworkAlert";
import TxButton from "../CommonForBoth/TxButton";
import { Modal } from "react-bootstrap";
/* Selectors */
import { selectChainId } from "../../store/user/selectors";
/* i18n and Config */
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { chain } from "../../config";
import "./style.scss";

const WelcomeModal = ({ t, show, onHide, openQuad, user, chainId }) => {
  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title className="modal-title-centered">{t("Welcome to Ensuro")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {chainId !== chain.id && <WrongNetworkAlert />}
        <p>{t("WelcomeToEnsuroKYCMessage")}</p>
        <p>
          <a href="https://www.youtube.com/watch?v=rKV3ZKnwMZ0" target="_blank" rel="noopener noreferrer">
            {t("KYBGuide")}
          </a>
        </p>
        <p>
          <a href="https://www.youtube.com/watch?v=AbTCEFmjkW4" target="_blank" rel="noopener noreferrer">
            {t("KYCGuide")}
          </a>
        </p>
      </Modal.Body>
      <Modal.Footer>
        <TxButton
          chainId={chainId}
          onClick={openQuad}
          disabled={chainId !== chain.id || (user && !user.canTransact)}
          text={t("Continue")}
        />
      </Modal.Footer>
      {user && !user.canTransact && (
        <p className="read-only">{t("Read-only mode. Connect to a wallet to perform transactions.")}</p>
      )}
    </Modal>
  );
};

WelcomeModal.propTypes = {
  t: PropTypes.any,
};

const mapStateToProps = (state) => {
  const user = state.UserReducer;
  const chainId = selectChainId(user);
  return { user, chainId };
};

export default connect(mapStateToProps)(withTranslation()(WelcomeModal));
